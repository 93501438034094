import React from 'react';
class Resume extends React.Component {
    
    render() {
      return (
          <div style={{paddingBottom:'2rem'}} className='background-div'>
              <div  style={{color: 'orange', }}>
                <h4>Resume</h4>
              </div>
              <iframe src="https://drive.google.com/file/d/1k8lxxvNbvuDGNw_qJO8WRky3raIELNTt/preview" width="800" height="1032" allow="autoplay" title='Resume' style={{paddingTop:'1rem'}} ></iframe>
          </div>
      );
    }
}
export default Resume;