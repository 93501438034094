import React from 'react';
import { Container, Carousel, Image,Row, Col } from 'react-bootstrap';
class Experience extends React.Component {
    
    render() {
      return (
        <Container>
            <Row style={{color:'orange', marginBottom:'5rem', marginTop:'3rem'}}>
                <Col><h4>Experience</h4></Col>
            </Row>
            <Row style={{color:'white', textAlign:'left', marginBottom:'2rem'}}>
                <Col xs={4}>
                    <h4>Software Engineer Internship</h4>
                    <h6>May 2021 - August 2021</h6>
                </Col>
                <Col xs={7} style={{ marginLeft:'3rem'}}>
                    <h4 style={{color:'#256CEA'}}> Disney | Office of the CTO</h4>
                    <ul>
                        <li><h6>Developed a full-stack Disney employee rewards system with admin functionality. Built with
                                React, Bootstrap, Node.js, Express, MySQL, and AWS S3. Deployed application using AWS
                                services and Docker.</h6></li>
                        <li><h6>Successfully designed and implemented a cross platform, authentication-protected, video streaming application for internal Disney owned live streams. Written in Dart using Flutter, Firebase Services, and Node.js.</h6></li>
                        <li><h6>Utilized testing technologies such as Jest and Supertest to ensure functionality and security with over 90% code coverage of applications.</h6></li>
                        <li><h6>Participated in daily meetings, executive presentations, and code reviews to gain professional and leadership experience. </h6></li>
                    </ul>

                </Col>
            </Row>
            <Row style={{color:'white', textAlign:'left',  marginBottom:'2rem'}}>
                <Col xs={4}>
                    <h4>Undergraduate Research Web Developer</h4>
                    <h6>January 2021 - Present</h6>
                </Col>
                <Col xs={7} style={{ marginLeft:'3rem'}}>
                    <h4 style={{color:'#256CEA'}}>Engaging Learning Laboratory | University of Florida</h4>
                    <ul>
                        <li><h6>Implementing a React web application, deployed on the Heroku cloud, using JavaScript, Node.js, and the Ardublockly library to build a block-based programming environment for children in K-12 Education learning how to program.</h6></li>
                        <li><h6>Lead front-end software team focused on user-interface design, interaction, and functionality. Mentor, support, and organize group members on a weekly basis. </h6></li>
                        <li><h6>Continuously assist in automated testing, deployment, and maintenance as well as performing user/usability tests on students, mentors, and the staff. </h6></li>
                        
                    </ul>

                </Col>
            </Row>
            <Row style={{color:'white', textAlign:'left',  marginBottom:'2rem'}}>
                <Col xs={4}>
                    <h4>Gator Wellness Web Application</h4>
                    <h6>August 2020 - December 2020</h6>
                </Col>
                <Col xs={7} style={{ marginLeft:'3rem'}}>
                    <h4 style={{color:'#256CEA'}}> Student Wellness Center | University of Florida</h4>
                    <ul>
                        <li><h6>Led a team that developed a mental health application for students while using the agile software development methodology.</h6></li>
                        <li><h6>Coordinated weekly meetings with client (Student Wellness Center) to discuss application requirements and features</h6></li>
                        <li><h6>Implemented application using React, Express, Node.js, and MongoDB.</h6></li>
                        <li><h6>Utilized APIs such as Auth0, UF, Reddit, Sentiment Analysis, Maps, and Axios. </h6></li>
                        <li><h6>Deployed to Heroku to make application available to university students. </h6></li>
                    </ul>

                </Col>
            </Row>
            <Row style={{color:'white', textAlign:'left',  marginBottom:'2rem'}}>
                <Col xs={4}>
                    <h4>Executive Committee Officer and Community Service Chairman</h4>
                    <h6>September 2019 - Present</h6>
                </Col>
                <Col xs={7} style={{ marginLeft:'3rem'}}>
                    <h4 style={{color:'#256CEA'}}>Sigma Chi Fraternity</h4>
                    <ul>
                        <li><h6>Contributed to development of chapter policies to improve members’ GPAs, involvement on campus and chapter productivity. </h6></li>
                        <li><h6>Organized and led various service events to promote fraternity’s community involvement. Awarded highest community service IFC honor in recognition of outstanding participation and commitment to community service. </h6></li>                       
                    </ul>

                </Col>
            </Row>
            <Row style={{color:'white', textAlign:'left',  marginBottom:'2rem'}}>
                <Col xs={4}>
                    <h4>Other Projects and Involvement</h4>
                 
                </Col>
                <Col xs={7} style={{ marginLeft:'3rem'}}>
                    <h4 style={{color:'#256CEA'}}>University of Florida</h4>
                    <ul>
                        <li><h6>GatorNFT Website (August 2021 - Present) </h6><p>Developing NFT website for the University of Florida's Crypto Club with a focus on the user interface using React.</p></li>
                        <li><h6>Software Engineering Club (August 2019 - May 2021)</h6><p>Participated in various workshops exploring popular software languages, databases and concepts. Collaborated on career readiness and extracurricular projects.</p></li>
                        <li><h6>Family Organization Application (May 2020 - August 2020)</h6><p>Developed a personal project outside of my course work to explore web development and software creativity. Created a simple Angular web application to help families manage everyday tasks including budgets, chores, groceries, events, and meal management features.</p></li>                       
                    </ul>

                </Col>
            </Row>
        </Container>
      );
    }
}
export default Experience;