import React from 'react';
import { Container, Carousel, Image,Row, Col, Button } from 'react-bootstrap';
import headshot from '../assets/headshot1.jpg'
class About extends React.Component {
    
    render() {
      return (
         
              
                  <Container style={{color:'orange',paddingTop:'4rem', paddingBottom:'7rem', marginTop:'0rem', backgroundColor:'#151515'}} fluid>
                      <Row><Col><Button style={{margin:'2rem'}} href='https://drive.google.com/file/d/1i6LaGYSgsqNa0R2QEZ9frO9NxYM-0f24/preview' variant='warning'>View Resume</Button></Col></Row>
                      <Row><Col>
                        <h4 style={{marginBottom:'4rem', marginTop:'2rem'}}>About</h4>
                      </Col></Row>
                      <Row style={{color:'white'}}>
                            <Col sm={6}>
                            <Image width='50%' src={headshot}  roundedCircle>
                                    </Image>
                            </Col>
                              <Col >
                                  <h3 style={{marginBottom:'2rem', textAlign:'left'}}>Hello! I'm Mack Hummel - A Software Engineer from Boulder, Colorado</h3>
                                  <p style={{textAlign:'left'}}>I am currently a senior at the University of Florida graduating in the Spring of 2022. I've been pursuing my BS in Computer Science and a minor in Mathematics since 2018. My primary interest lies in full stack web and mobile developement. Currently, I am seeking a full time software engineering role in the US.</p>
                                  
                              </Col>
                      </Row>
                  </Container>
              
          
      );
    }
}
export default About;