import React from 'react';
import { Container, Carousel, Image,Row, Col } from 'react-bootstrap';
import { SocialIcon } from 'react-social-icons';
import colorado from '../assets/colorado.jpg';
import florida from '../assets/florida.jpg'
import software from '../assets/software.jpg'

class Home extends React.Component {
    render() {
      return (
          <Container fluid>
              <Container fluid style={{paddingTop:'5vh', paddingBottom:'3vh',backgroundColor:'#151515'}}>
        <Row><Col>
                  <SocialIcon style={{marginRight:'1rem'}} url="https://www.linkedin.com/in/mack-hummel/" fgColor='white' ></SocialIcon>
                  <SocialIcon style={{marginRight:'1rem'}} url="https://github.com/mackhummel" bgColor='white' ></SocialIcon>
                  <SocialIcon  url="mailto:mackrhummel@gmail.com" bgColor='red' fgColor='white'></SocialIcon>
              </Col></Row>
        </Container>
              <Carousel touch>
                  <Carousel.Item>
                      <div className='fill'><img src={colorado}  style={{opacity:'0.7'}} /></div>
                  <Carousel.Caption>
                    <h1>Boulder, Colorado </h1>
                </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                  <div className='fill'><img src={florida}  style={{opacity:'0.7'}} /></div>
                  <Carousel.Caption>
                    <h1>University of Florida</h1>
                </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                  <div className='fill'><img src={software}  style={{opacity:'0.5'}} /></div>
                  <Carousel.Caption>
                      <br/>
                    <h1>Software Engineer</h1>
                </Carousel.Caption>
                  </Carousel.Item>
                  {/* <Carousel.Item>
                      <img src={hobbies} width="100%" style={{opacity:'0.7'}}/>
                  <Carousel.Caption>
                    <h1 style={{marginBottom:'1rem'}}>Hobbies</h1>
                    <Container style={{width:'90%'}}>
                        <Row>
                            <Col>
                                <ul>
                                    <li><h5>Hiking</h5></li>
                                    <li><h5>Moutain Biking</h5></li>
                                    <li><h5>Football</h5></li>
                                    <li><h5>Basketball</h5></li>
                                    <li><h5>Frisbee Golf</h5></li>
                                    <li><h5>Video Games</h5></li>
                                    <li><h5>Poker</h5></li>
                                    <li><h5>Backgammon</h5></li>
                                    <li><h5>Chess</h5></li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </Carousel.Caption>
                  </Carousel.Item> */}
              </Carousel>
          </Container>
      );
    }
}
export default Home;
