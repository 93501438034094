import React from 'react';
import { Container, Carousel, Image,Row, Col } from 'react-bootstrap';

class EductSkill extends React.Component {
    
    render() {
      return (
        <Container className='waves'  style={{paddingBottom:'3rem'}} fluid>
            <Row style={{color:'#282c34', marginBottom:'5rem', paddingTop:'3rem'}}>
                <Col><h4>Eduction & Skills</h4></Col>
            </Row>
            <Row style={{color:'black', textAlign:'left', marginBottom:'2rem' ,marginLeft:'5rem'}}>
                <Col xs={5}>
                    <h4>University of Florida</h4>
                    <h6>August 2018 - May 2022</h6>
                </Col>
                <Col style={{ marginLeft:'3rem'}}>
                    <h4 style={{color:'#b8860b', fontWeight:'bold'}}> B.S. Computer Science with Minor in Mathematics</h4>
                    <ul>
                        
                        <li><h6>GPA: 3.84 | Major GPA: 3.94</h6></li>
                        <li><h6>Honors: Presidential Honor Roll (Fall 2020 & Spring 2021), Deans List (3 semesters)</h6></li>
                        <li><h6>Relevant Coursework: 
                            <ul style={{marginTop:'1rem'}}>
                                <li><p>Data Structures and Algorithms</p></li>
                                <li><p>Software Engineering</p></li>
                                <li><p>Operating Systems</p></li>
                                <li><p>Information & Database Systems</p></li>
                                <li><p>Data Science</p></li>
                                <li><p>Computer Architecture </p></li>
                            </ul>
                            </h6></li>
                    </ul>

                </Col>
            </Row>
            <Row style={{color:'black', textAlign:'left', marginBottom:'2rem', marginLeft:'5rem', marginTop:'5rem'}}>
                <Col xs={5}>
                    <h4>Technical Skills</h4>
                    
                </Col>
                
            </Row>
            <Row style={{color:'black', textAlign:'center', marginBottom:'2rem', }}>
                <Col style={{border:'solid', margin:'1rem',  backgroundColor:'rgb(0,0,0,0.8)'}}>
                <Row>
                    <h4 style={{color:'#b8860b'}}>Languages</h4>
                </Row>
                <Row>
                    <h6 style={{color:'white'}}>Javascript</h6>
                </Row>
                <Row>
                    <h6 style={{color:'white'}}>C++</h6>
                </Row>
                <Row>
                    <h6 style={{color:'white'}}>Java</h6>
                </Row>
                <Row>
                    <h6 style={{color:'white'}}>Flutter</h6>
                </Row>
                <Row>
                    <h6 style={{color:'white'}}>Python (inexperienced)</h6>
                </Row>
                </Col>
                <Col style={{border:'solid', margin:'1rem',  backgroundColor:'rgb(0,0,0,0.8)'}}>
                <Row>
                    <h4 style={{color:'#b8860b'}}>Frameworks</h4>
                </Row>
                <Row>
                    <h6 style={{color:'white'}}>React</h6>
                </Row>
                <Row>
                    <h6 style={{color:'white'}}>Flutter</h6>
                </Row>
                <Row>
                    <h6 style={{color:'white'}}>Angular</h6>
                </Row>
                
                </Col>
                <Col style={{border:'solid', margin:'1rem',  backgroundColor:'rgb(0,0,0,0.8)'}}>
                <Row>
                    <h4 style={{color:'#b8860b'}}>Back-End</h4>
                </Row>
                <Row>
                    <h6 style={{color:'white'}}>Node.js</h6>
                </Row>
                <Row>
                    <h6 style={{color:'white'}}>Express</h6>
                </Row>
                
                </Col>
                <Col style={{border:'solid', margin:'1rem',   backgroundColor:'rgb(0,0,0,0.8)'}}>
                <Row>
                    <h4 style={{color:'#b8860b'}}>Front-End</h4>
                </Row>
                <Row>
                    <h6 style={{color:'white'}}>HTML</h6>
                </Row>
                <Row>
                    <h6 style={{color:'white'}}>CSS/SCSS</h6>
                </Row>
                <Row>
                    <h6 style={{color:'white'}}>Bootstrap</h6>
                </Row>
                
                </Col>
            </Row>
            <Row style={{color:'black', textAlign:'center', marginBottom:'2rem', marginLeft:'0rem'}}>
                <Col style={{border:'solid', margin:'1rem', backgroundColor:'rgb(0,0,0,0.8)'}}>
                <Row>
                    <h4 style={{color:'#b8860b'}}>Cloud</h4>
                </Row>
                <Row>
                    <h6 style={{color:'white'}}>AWS</h6>
                </Row>
                <Row>
                    <h6 style={{color:'white'}}>Docker</h6>
                </Row>
                <Row>
                    <h6 style={{color:'white'}}>Heroku</h6>
                </Row>
                
                </Col>
                <Col style={{border:'solid', margin:'1rem',  backgroundColor:'rgb(0,0,0,0.8)'}}>
                <Row>
                    <h4 style={{color:'#b8860b'}}>Database</h4>
                </Row>
                <Row>
                    <h6 style={{color:'white'}}>SQL</h6>
                </Row>
                <Row>
                    <h6 style={{color:'white'}}>MongoDB</h6>
                </Row>
                <Row>
                    <h6 style={{color:'white'}}>Firebase</h6>
                </Row>
                <Row>
                    <h6 style={{color:'white'}}>Sequelize</h6>
                </Row>
                
                </Col>
                
            </Row>
            
        </Container>
      );
    }
}
export default EductSkill;