import './App.css';
import { SocialIcon } from 'react-social-icons';
import * as Scroll from 'react-scroll';
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import {Navbar, Nav, Container, Row, Col} from 'react-bootstrap'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  
} from "react-router-dom";
import Home from './components/Home';
import Resume from './components/Resume';
import About from './components/About';
import Experience from './components/Experience';
import EductSkill from './components/EductSkill';
function App() {
  return (
    
      <div  className="App" style={{height: window.innerHeight}} >
        <Navbar bg="dark"  variant="dark" fixed="top" >
            <Link activeClass="active" to="home"  smooth={true} offset={0} duration={100}>
              <Navbar.Brand onClick={scroll.scrollToTop}  >
              <img
                style={{margin:'5px'}}
                src="/Logo.png"
                width="40"
                height="40"
                alt=""
              />
                         Mack Hummel
              </Navbar.Brand>
            </Link>
              <Nav >
                <Nav.Link ><Link activeClass="active" to="about"  smooth={true} offset={-65} duration={100} >About Me</Link></Nav.Link>
                <Nav.Link ><Link activeClass="active" to="experience"  smooth={true} offset={-65} duration={100} >Experience</Link></Nav.Link>
                <Nav.Link ><Link activeClass="active" to="educt"  smooth={true} offset={-65} duration={100} >Education & Skills</Link></Nav.Link>
              </Nav>
        </Navbar>
        <Container>
        <Row><Col>
                  <SocialIcon style={{marginRight:'1rem'}} url="https://www.linkedin.com/in/mack-hummel/" fgColor='white' ></SocialIcon>
                  <SocialIcon style={{marginRight:'1rem'}} url="https://github.com/mackhummel" bgColor='white' ></SocialIcon>
                  <SocialIcon  url="mailto:mackrhummel@gmail.com" bgColor='red' fgColor='white'></SocialIcon>
              </Col></Row>
        </Container>
        <Row><Home/></Row>
          <Row className='background-div' ><Element name='about'><About /></Element></Row>
          <Row className='background-div' ><Element name='experience'><Experience /></Element></Row>
          <Row className='background-div' ><Element name='educt'><EductSkill /></Element></Row>
      </div>
    
      
      
  );
}

export default App;
